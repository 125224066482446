import React, { useEffect, useState } from "react";
//COMPONENTES
import Banner from "../Banner";
import TipsVariantes from "../TipsVariantes";
import RatesData from "./RatesData";
//ESTILOS
import "./Tips.css";
//MEDIA
//SERVICIOS
import fglobales from "../../services/fglobales";

function Tips() {
  const [dataRates, setDataRates] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const dataRat = await fglobales.getDatosOrder(
          "rate",
          "status",
          true,
          "date"
        );
        setDataRates(dataRat);
      } catch (error) {
        // Manejar errores de manera adecuada
        console.error("Error al obtener datos de usuarios ganadores:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <Banner bannerName={"tips"} />
      <div className='grid grid-nogutter container-principal mt-0'>
        <div className='col-12 md:col-10 md:col-offset-1'>
          <h1 className='mt-0'>Tasas</h1>
          <div className='grid contentForm'>
            <div className='col-12 lg:col-10 lg:col-offset-1'>
              <RatesData dataRates={dataRates} />
            </div>
          </div>

          <h1>Tips</h1>
          <div className='grid contentForm'>
            <div className='col-12'>
              <TipsVariantes />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Tips;
