import React from "react";
// ROUTER
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
// COMPONENTES
import Register from "../pages/Register/Information";
import Error404 from "../pages/Error404";
import Titanes from "../pages/Titanes";
import Login from "../pages/Login/Information";
// import NotiBrilla from "../pages/NotiBrilla";
import NotiBrillaHistorical from "../pages/NotiBrillaHistorical";
import Profile from "../pages/Profile";
import ProfileUser from "../pages/Profile/EditUser";
import UpdateUser from "../pages/Profile/UpdateUser";
import Campaign from "../pages/Campaign";
import Winners from "../pages/Winners";
import Portal from "../pages/Portal";
import Lineas from "../pages/Lineas";
import Galeria from "../pages/Albumes/GaleriaItems";
import Marketplace from "../pages/Marketplace";
import ShoppingCart from "../pages/ShoppingCart";
import Tips from "../pages/Tips";

export default function AppRouter({ isLogged, configurations }) {
  const location = useLocation();

  const ProtectedRouter = ({ children }) => {
    if (!isLogged()) {
      return (
        <Navigate
          to='/login'
          state={{ location }}
        />
      );
    }

    return children;
  };

  const PublicRouter = ({ children }) => {
    if (isLogged()) {
      return (
        <Navigate
          to='/home'
          state={{ location }}
        />
      );
    }

    return children;
  };

  return (
    <Routes>
      <Route
        exact
        path='/registrate'
        element={
          <PublicRouter>
            <Register configurations={configurations} />
          </PublicRouter>
        }
      />
      <Route
        exact
        path='/login'
        element={
          <PublicRouter>
            <Login />
          </PublicRouter>
        }
      />
      {/* <Route
        exact
        path="/notibrilla"
        element={
          <ProtectedRouter>
            <NotiBrilla />
          </ProtectedRouter>
        }
      /> */}

      <Route
        exact
        path='/notibrilla'
        element={
          <ProtectedRouter>
            <NotiBrillaHistorical />
          </ProtectedRouter>
        }
      />
      <Route
        exact
        path='/concursos'
        element={
          <ProtectedRouter>
            <Campaign />
          </ProtectedRouter>
        }
      />
      <Route
        exact
        path='/ganadores'
        element={
          <ProtectedRouter>
            <Winners />
          </ProtectedRouter>
        }
      />
      <Route
        exact
        path='/portal'
        element={
          <ProtectedRouter>
            <Portal />
          </ProtectedRouter>
        }
      />
      <Route
        exact
        path='/portal/:id'
        element={
          <ProtectedRouter>
            <Galeria />
          </ProtectedRouter>
        }
      />

      <Route
        exact
        path='/tips'
        element={
          <ProtectedRouter>
            <Tips />
          </ProtectedRouter>
        }
      />
      <Route
        exact
        path='/lineas'
        element={
          <ProtectedRouter>
            <Lineas />
          </ProtectedRouter>
        }
      />
      <Route
        exact
        path='/perfil'
        element={
          <ProtectedRouter>
            <Profile />
          </ProtectedRouter>
        }
      />

      <Route
        exact
        path='/perfilusuario'
        element={
          <ProtectedRouter>
            <ProfileUser />
          </ProtectedRouter>
        }
      />
      <Route
        exact
        path='/perfilactualizado'
        element={
          <ProtectedRouter>
            <UpdateUser />
          </ProtectedRouter>
        }
      />
      <Route
        exact
        path='/tiendaolimpos'
        element={
          <ProtectedRouter>
            <Marketplace />
          </ProtectedRouter>
        }
      />
      <Route
        exact
        path='/Carrito'
        element={
          <ProtectedRouter>
            <ShoppingCart />
          </ProtectedRouter>
        }
      />
      <Route
        exact
        path='/home'
        element={<Titanes />}
      />
      <Route
        exact
        path='/'
        element={<Navigate to='/home' />}
      />
      <Route
        path='*'
        element={<Error404 />}
      />
    </Routes>
  );
}
