import React from "react";
//PRIMEREACT
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
//COMPONENTES
import LoaderSimple from "../LoaderSimple";
//ESTILOS
import "./Tips.css";
//MEDIA
//SERVICIOS

function RatesData({ dataRates }) {
  const comparativeTemplate = (rowData, { rowIndex }) => {
    // Si es el último registro, no se muestra comparación
    if (rowIndex === dataRates.length - 1)
      return (
        <i
          className='pi pi-minus'
          style={{ color: "gray" }}></i>
      );

    // Se obtiene el registro siguiente
    const nextRate = dataRates[rowIndex + 1].rateNumber;
    const currentRate = rowData.rateNumber;

    // Compara los valores y define el mensaje
    if (currentRate > nextRate) {
      return (
        <p className='tSubio'>
          <i className='pi pi-arrow-up'></i> Subio
        </p>
      );
    } else if (currentRate < nextRate) {
      return (
        <p className='tBajo'>
          <i className='pi pi-arrow-down'></i> Bajo
        </p>
      );
    } else {
      return (
        <p className='tEstable'>
          <i className='pi pi-minus'></i> Estable
        </p>
      );
    }
  };

  return (
    <>
      {dataRates.length > 0 ? (
        <DataTable
          value={dataRates}
          responsiveLayout='scroll'
          className='tableTitle'
          size='small'
          tableStyle={{ minWidth: "50rem" }}>
          <Column
            field='monthName'
            header='Mes'
            headerClassName='centered-header'
            bodyStyle={{ textAlign: "center" }}></Column>
          <Column
            field='rate'
            header='Tasa de interes'
            headerClassName='centered-header'
            bodyStyle={{ textAlign: "center" }}></Column>
          <Column
            field='rate'
            header='Comparativo'
            body={comparativeTemplate}
            headerClassName='centered-header'
            bodyStyle={{ textAlign: "center" }}></Column>
        </DataTable>
      ) : (
        <LoaderSimple />
      )}
    </>
  );
}

export default RatesData;
