import React, { useState, useEffect } from "react";
//PRIMEREACT
//COMPONENTES
import LoaderSimple from "../LoaderSimple";
import History from "./History";
import HistoryMovil from "./HistoryMovil";
import Pdf from "./Pdf";
//ESTILOS
import "./TipsVariantes.css";
//CONTEXT
//FIREBASE
import {
  collection,
  query,
  orderBy,
  onSnapshot,
  where,
} from "firebase/firestore";
import { db } from "../../firebase";

function TipsVariantes() {
  const [notiHistorial, setNotiHistorial] = useState(null);
  const [selectNoti, setSelectNoti] = useState(null);
  const [downloadPdf, setDownloadPdf] = useState(false);

  useEffect(() => {
    // Filtro los documentos que tengan status: true
    const q = query(
      collection(db, "tips"),
      where("status", "==", true), // Filtro para status true
      orderBy("date", "desc") // Ordena por fecha en orden descendente
    );

    const unlistenI = onSnapshot(q, (docs) => {
      let news = [];

      docs.forEach((doc) => {
        news.push({ ...doc.data(), id: doc.id });
      });

      setNotiHistorial(news);
      setSelectNoti(news[0]); // Selecciona el primero por defecto
    });

    return () => {
      unlistenI();
    };
  }, []);

  return (
    <>
      {notiHistorial ? (
        <div className='grid'>
          <div className='col-12 md:col-3'>
            <h3 className='text-center m-0'>Documentos</h3>
            {notiHistorial.map((n, index) => {
              return (
                <div
                  key={index}
                  className='visiblemd'>
                  <History
                    newsData={n}
                    setSelectNoti={setSelectNoti}
                    selectNoti={selectNoti}
                  />
                </div>
              );
            })}

            <div className='visiblesm'>
              <HistoryMovil
                newsData={notiHistorial}
                setSelectNoti={setSelectNoti}
                selectNoti={selectNoti}
              />
            </div>
          </div>

          {selectNoti ? (
            <div className='col-12 md:col-9 borderCanpaña'>
              <Pdf
                selectNoti={selectNoti}
                downloadPdf={downloadPdf}
                setDownloadPdf={setDownloadPdf}
              />
            </div>
          ) : (
            <LoaderSimple />
          )}
        </div>
      ) : (
        <LoaderSimple />
      )}
    </>
  );
}

export default TipsVariantes;
