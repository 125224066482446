import React, { useState } from "react";
//ESTILOS
import "./BtnPiter.css";
import mspiterStatic from "../../assets/images/mister-piter.png"; // Imagen estática
import mspiterAnimation from "../../assets/images/ms-piter.gif"; // GIF animado

function BtnPiter() {
  const [isHovering, setIsHovering] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);
  console.log("🚀 ~ BtnPiter ~ isAnimating:", isAnimating);

  const handleMouseEnter = () => {
    setIsHovering(true);
    setIsAnimating(true);
  };

  const handleMouseLeave = () => {
    const gifDuration = 3200; // Duración estimada del GIF en milisegundos
    setTimeout(() => {
      setIsHovering(false);
    }, gifDuration); // Cambia a la imagen estática después del tiempo estimado del GIF
  };

  return (
    <div className='btn-rank'>
      <a
        href='https://wa.me/+573054302791'
        target='_blank'
        rel='noreferrer'
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}>
        <img
          src={isHovering ? mspiterAnimation : mspiterStatic} // Cambia entre GIF y la imagen estática
          alt='Ms Piter'
        />
      </a>
    </div>
  );
}

export default BtnPiter;
