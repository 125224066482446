import React, { useEffect, useState } from "react";
//PRIMEREACT
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
//COMPONENTES
import LoaderSimple from "../LoaderSimple";
//ESTILOS
import "./Winners.css";
//MEDIA
//FIREBASE
import { collection, query, onSnapshot, orderBy } from "firebase/firestore";
import { db } from "../../firebase";
//SERVICIOS
import moment from "moment";
import "moment/locale/es";

function Training({ dataUser, configurations }) {
  const [trainingData, setTrainingData] = useState([]);

  useEffect(() => {
    const q = query(
      collection(db, "training"),
      orderBy("dateInit", "asc")
      // where("districtId", "==", dataUser.districtId)
    );
    const unlistenI = onSnapshot(q, (docs) => {
      let dataTrain = [];

      docs.forEach((doc) => {
        let docData = doc.data();

        docData.dateInicial = docData.dateInit
          ? moment(doc.data().dateInit.toDate()).format("DD/MM/YYYY, h:mm A")
          : "---";

        docData.dateFinal = docData.dateEnd
          ? moment(doc.data().dateEnd.toDate()).format("DD/MM/YYYY, h:mm A")
          : "---";

        if (docData.status === true) {
          if (
            docData.districtId === configurations.codeDistrictGeneral ||
            docData.districtId === dataUser.districtId
          ) {
            dataTrain.push({
              ...doc.data(),
              id: doc.id,
              dateInicial: docData.dateInicial,
              dateFinal: docData.dateFinal,
            });
          }
        }
      });
      setTrainingData(dataTrain);
    });
    return () => {
      unlistenI();
    };
  }, [dataUser.districtId, configurations.codeDistrictGeneral]);

  const nameDate = (rowData) => {
    return (
      <>
        <p className='tcampanas m-0'>{rowData.name}</p>
        <p className='subtcampanas m-0'>{rowData.dateInicial}</p>
        <p className='subtcampanas m-0'>{rowData.dateFinal}</p>
      </>
    );
  };

  const changeModalidad = (rowData) => {
    if (rowData.modality === 1) {
      return <p className='tableData m-0'>Presencial</p>;
    } else {
      return <p className='tableData m-0'>Virtual</p>;
    }
  };

  const addressLink = (rowData) => {
    if (rowData.modality === 1) {
      return <p className='tableData m-0'>{rowData.address}</p>;
    } else {
      return (
        <p>
          <a
            href={rowData.link}
            target='_blank'
            rel='noreferrer'
            className='linkTable'>
            <b>Ir a link</b>
          </a>
        </p>
      );
    }
  };

  return (
    <>
      {trainingData ? (
        <div>
          <DataTable
            value={trainingData}
            responsiveLayout='scroll'
            className='tableTitle'>
            <Column
              field='namer'
              header='Nombre y Fecha'
              body={nameDate}></Column>
            <Column
              field='districtDesc'
              header='Distrito'></Column>
            <Column
              field='hour'
              header='Hora'></Column>
            <Column
              field='modality'
              header='Modalidad'
              body={changeModalidad}></Column>
            <Column
              field='link'
              header='Dirección/Link'
              body={addressLink}></Column>
          </DataTable>
        </div>
      ) : (
        <LoaderSimple />
      )}
    </>
  );
}

export default Training;
