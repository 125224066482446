import {
  query,
  where,
  getDocs,
  getDoc,
  collection,
  serverTimestamp,
  updateDoc,
  doc,
  deleteDoc,
  setDoc,
  addDoc,
  orderBy,
  onSnapshot,
} from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import { db, storage } from "../firebase";

const fglobales = {};

fglobales.getExistNoId = async (collectionText, variableText, value) => {
  const queryRef = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );
  const docSnap = await getDocs(queryRef);

  return docSnap.size > 0;
};

fglobales.getDocId = async (collectionText, id) => {
  const q = doc(db, collectionText, id);
  return await getDoc(q);
};

fglobales.onDocId = async (collectionText, id) => {
  const q = doc(db, collectionText, id);
  return onSnapshot(q);
};

fglobales.addDocs = async (collectionText, data, dataUser) => {
  const ref = doc(collection(db, collectionText));
  try {
    await setDoc(ref, {
      ...data,
      id: ref.id,
      timestamp: serverTimestamp(),
      creatorUser: dataUser,
    });
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

fglobales.addDocId = async (collectionText, data, id) => {
  const q = doc(db, collectionText, id);
  return await setDoc(q, data);
};

fglobales.updateDoc = async (collectionText, id, data) => {
  const q = doc(db, collectionText, id);
  try {
    await updateDoc(q, data);
    return true;
  } catch (e) {
    return false;
  }
};

fglobales.deleteDoc = async (collectionText, id) => {
  const q = doc(db, collectionText, id);

  try {
    await deleteDoc(q);
    return true;
  } catch (e) {
    console.log("Transacción fallida documento: ", e);
    return false;
  }
};

fglobales.getConfig = async (collectionText) => {
  const querySnapshot = await getDocs(collection(db, collectionText));
  var data = {};
  querySnapshot.forEach((doc) => {
    data[doc.id] = doc.data();
  });
  return data;
};

fglobales.getCustomers = async (collectionText) => {
  const querySnapshot = await getDocs(collection(db, collectionText));
  let result = []; // clear previous results
  querySnapshot.forEach((doc) => {
    let data = doc.data();
    let docData = {
      id: data.id,
      name: data.businessName,
      email: data.email,
      nit: data.nit,
    };
    result.push(docData);
  });
  return result;
};

fglobales.getCountries = async () => {
  const querySnapshot = await getDocs(collection(db, "countries"));
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

fglobales.getDepartments = async (docText) => {
  const q = query(
    collection(db, "countries", docText, "states"),
    orderBy("nombre")
  );

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

fglobales.getMunicipalities = async (docTextC, docTextS) => {
  const q = query(
    collection(db, "countries", docTextC, "states", docTextS, "municipalities"),
    orderBy("municipality")
  );

  const querySnapshot = await getDocs(q);
  let data = [];
  querySnapshot.forEach((doc) => {
    data.push(doc.data());
  });
  return data;
};

fglobales.getUsersDatos = async (collectionText, variableText, value) => {
  const q = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );
  const querySnapshot = await getDocs(q);
  let docData = [];

  querySnapshot.forEach((doc) => {
    docData = doc.data();
  });

  return docData;
};

fglobales.getDatosAB = async (collectionText, variableText, value) => {
  const q = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );
  const querySnapshot = await getDocs(q);
  let docData = [];

  querySnapshot.forEach((doc) => {
    docData.push({ ...doc.data() });
  });

  return docData;
};

fglobales.getDatosABC = async (collectionText, variableText, value) => {
  const q = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );
  const querySnapshot = await getDocs(q);
  let docData = [];

  querySnapshot.forEach((doc) => {
    docData.push({ ...doc.data() });
  });

  return docData;
};

fglobales.getDatosOrder = async (
  collectionText,
  variableText,
  value,
  order
) => {
  const q = query(
    collection(db, collectionText),
    where(variableText, "==", value),
    orderBy(order, "desc")
  );
  const querySnapshot = await getDocs(q);
  let docData = [];

  querySnapshot.forEach((doc) => {
    docData.push({ ...doc.data() });
  });

  return docData;
};

fglobales.addNoti = async (
  collectionText,
  dataNoti,
  formData,
  dataUser,
  imgData,
  emailComunicaciones
) => {
  const tiempoTranscurrido = Date.now();
  const q = doc(db, collectionText, `${dataNoti.id}`);
  const qSub = collection(q, "userNews");

  const refImg = ref(
    storage,
    `noticias/${dataNoti.desc}/${dataUser.userId}/${tiempoTranscurrido}`
  );

  try {
    const imgD = imgData;
    uploadBytes(refImg, imgD).then((snapshot) => {
      try {
        getDownloadURL(refImg)
          .then((url) => {
            try {
              addDoc(qSub, {
                noticia: formData.noticia,
                imgNoti: url,
                userId: dataUser.userId,
                nameUser: dataUser.fullName,
                notibrillaId: dataNoti.id,
                to: emailComunicaciones,
                desc: dataNoti.desc,
                mobilePhone: dataUser.mobilePhone,
                dni: dataUser.dni,
                timestamp: serverTimestamp(),
              });
            } catch (e) {
              console.log("Transacción fallida documento: ", e);
            }
          })
          .catch((error) => {
            console.log("error encontrado", error);
          });
      } catch (e) {
        console.log("Transacción fallida: ", e);
      }
    });

    return {
      icon: "pi-check-circle",
      colorIcon: "green-500",
      headerMessage: `¡Felicidades!`,
      message: `Hemos recibido tu nota con éxito, ${dataUser.fullName}.`,
    };
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

fglobales.addPerfilImg = async (collectionText, dataUser, imgData) => {
  const q = doc(db, collectionText, dataUser.userId);

  const refImg = ref(
    storage,
    `UserPerfil/${dataUser.userId}/${dataUser.userId}`
  );

  try {
    const imgD = imgData;
    await uploadBytes(refImg, imgD).then((snapshot) => {
      try {
        getDownloadURL(refImg)
          .then((url) => {
            try {
              updateDoc(q, {
                userImg: url,
              });
            } catch (e) {
              console.log("Transacción fallida documento: ", e);
            }
          })
          .catch((error) => {
            console.log("error encontrado urlImg", error);
          });
      } catch (e) {
        console.log("Transacción subida de imagen: ", e);
      }
    });

    return {
      icon: "pi-check-circle",
      colorIcon: "green-500",
      headerMessage: `¡Felicidades!`,
      message: "Tu foto de perfil se actualizo",
    };
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

//SUBIR IMAGENES DE LA SUCURSAL
fglobales.addSucursalImg = async (
  collectionText,
  dataUser,
  dataImg,
  campoImg
) => {
  const q = doc(db, collectionText, dataUser.userId);

  const refImg = ref(storage, `Sucursal/${dataUser.userId}/${campoImg}`);

  try {
    const imgD = dataImg;
    await uploadBytes(refImg, imgD).then((snapshot) => {
      try {
        getDownloadURL(refImg)
          .then((url) => {
            try {
              updateDoc(q, {
                [`${campoImg}`]: url,
              });
            } catch (e) {
              console.log("Transacción fallida documento: ", e);
            }
          })
          .catch((error) => {
            console.log("error encontrado urlImg", error);
          });
      } catch (e) {
        console.log("Transacción subida de imagen: ", e);
      }
    });

    return {
      icon: "pi-check-circle",
      colorIcon: "green-500",
      headerMessage: `¡Felicidades!`,
      message: `Foto subida con exito.`,
    };
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

fglobales.addComments = async (
  collectionText,
  selectCampana,
  formData,
  dataUser
) => {
  const q = doc(db, collectionText, `${selectCampana.id}`);
  const qSub = collection(q, "comments");
  const tiempoTranscurrido = Date.now();

  const avatar =
    "https://firebasestorage.googleapis.com/v0/b/incentivos-brilla.appspot.com/o/plantilla%2Favatar.png?alt=media&token=b72b9049-e4c5-4b52-8fc3-56fc0e377de5";

  try {
    await addDoc(qSub, {
      comments: formData.comments,
      userId: dataUser.userId,
      nameUser: dataUser.fullName,
      avatar: dataUser.userImg ? dataUser.userImg : avatar,
      dataBranch: dataUser.dataBranch,
      idCampaign: selectCampana.id,
      likeComment: false,
      likes: [],
      timestamp: serverTimestamp(),
      date: tiempoTranscurrido,
    });

    return {
      icon: "pi-check-circle",
      colorIcon: "green-500",
      headerMessage: `¡Felicidades!`,
      message: "Tu comentario se publico exitosamente",
    };
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

fglobales.addSubComments = async (
  collectionText,
  showComments,
  formData,
  dataUser
) => {
  const q = query(
    collection(
      db,
      collectionText,
      showComments.idCampaign,
      "comments",
      showComments.id,
      "subComments"
    )
  );
  const tiempoTranscurrido = Date.now();

  const avatar =
    "https://firebasestorage.googleapis.com/v0/b/incentivos-brilla.appspot.com/o/plantilla%2Favatar.png?alt=media&token=b72b9049-e4c5-4b52-8fc3-56fc0e377de5";

  try {
    await addDoc(q, {
      subComments: formData.subComments,
      userId: dataUser.userId,
      commentsId: showComments.id,
      nameUser: dataUser.fullName,
      avatar: dataUser.userImg ? dataUser.userImg : avatar,
      idCampaign: showComments.idCampaign,
      timestamp: serverTimestamp(),
      date: tiempoTranscurrido,
    });

    return true;
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

fglobales.getSur = async (collectionText, id) => {
  const q = doc(db, collectionText, id);
  const docQ = await getDoc(q);

  if (docQ.exists()) {
    return docQ.data();
  }
};

fglobales.addAdviser = async (collectionText, data, dataUserPre) => {
  const ref = doc(collection(db, collectionText));
  try {
    await setDoc(ref, {
      ...data,
      codeAccessUser: dataUserPre.codeAccess,
      timestamp: serverTimestamp(),
    });
  } catch (e) {
    console.log("Transacción fallida: ", e);
  }
};

fglobales.getDatosAdviser = (collectionText, variableText, value) => {
  const q = query(
    collection(db, collectionText),
    where(variableText, "==", value)
  );
  onSnapshot(q, (docs) => {
    let data = [];

    docs.forEach((doc) => {
      data.push({ ...doc.data() });
    });
    return data;
  });
};

fglobales.getCollectionData = async (collectionName) => {
  const querySnapshot = await getDocs(collection(db, collectionName));

  let data = [];
  querySnapshot.forEach((doc) => {
    let docData = doc.data();
    docData.id = doc.id;
    data.push(docData);
  });
  return data;
};

//Obtener data si los elementos del array estan en un In
fglobales.getDatosArray = async (collectionText, variableText, values) => {
  const chunkSize = 10;
  const valueChunks = [];

  for (let i = 0; i < values.length; i += chunkSize) {
    const chunk = values.slice(i, i + chunkSize);
    valueChunks.push(chunk);
  }

  let docData = [];

  for (const chunk of valueChunks) {
    const q = query(
      collection(db, collectionText),
      where(variableText, "in", chunk)
    );

    const querySnapshot = await getDocs(q);

    querySnapshot.forEach((doc) => {
      docData.push({ ...doc.data() });
    });
  }

  return docData;
};

//Obtener los id de todos los ganadores de insignias

fglobales.getIdWinners = async (collectionText) => {
  // const querySnapshot = await getDocs(collection(db, collectionText));

  const q = query(collection(db, collectionText), where("status", "==", true));
  const querySnapshot = await getDocs(q);
  let docData = [];

  querySnapshot.forEach((doc) => {
    docData.push(doc.data().userId);
  });

  return docData;
};

//Datos con el fltro de distrito incluido
fglobales.getDatosFilDis = async (
  collectionText,
  variableText,
  value,
  dataUser,
  configurations
) => {
  const q = query(
    collection(db, collectionText),
    where(variableText, "==", value),
    orderBy("date")
  );
  const querySnapshot = await getDocs(q);
  let docData = [];

  querySnapshot.forEach((doc) => {
    if (
      doc.data().districtId === dataUser.districtId ||
      doc.data().districtId === configurations.codeDistrictGeneral
    ) {
      docData.push({ ...doc.data() });
    }
  });

  return docData;
};

export default fglobales;
