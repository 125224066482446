import React, { useState, useEffect } from "react";
//PRIMEREACT
//COMPONENTES
import LoaderSimple from "../LoaderSimple";
import History from "./History";
import HistoryMovil from "./HistoryMovil";
import Pdf from "./Pdf";
import UploadsNoti from "./UploadsNoti";
//ESTILOS
import "./NotiBrillaHistorical.css";
//CONTEXT
import { useUsuario } from "../../context/usuario-context";
//FIREBASE
import {
  collection,
  query,
  orderBy,
  limit,
  onSnapshot,
} from "firebase/firestore";
import { db } from "../../firebase";

function Campaign() {
  const { dataUser, configurations } = useUsuario();
  const [notiHistorial, setNotiHistorial] = useState(null);
  const [selectNoti, setSelectNoti] = useState(null);
  const [downloadPdf, setDownloadPdf] = useState(false);

  useEffect(() => {
    const q = query(
      collection(db, "notiBrilla"),
      orderBy("date", "desc"),
      limit(5)
    );
    const unlistenI = onSnapshot(q, (docs) => {
      let news = [];

      docs.forEach((doc) => {
        const data = doc.data();
        // Filtramos los que tengan status true antes de llenar el state
        if (data.status === true) {
          news.push({ ...data, id: doc.id });
        }
      });

      setNotiHistorial(news);
      setSelectNoti(news[0]);
    });
    return () => {
      unlistenI();
    };
  }, []);

  return (
    <>
      <div className='grid grid-nogutter container-principal'>
        <div className='col-12 md:col-10 md:col-offset-1'>
          <h1 className='mt-0'>Notibrilla</h1>
          <br />
          <div className='grid'>
            <div className='col-12 contentForm'>
              {notiHistorial ? (
                <div className='grid'>
                  <div className='col-12 md:col-3'>
                    <h3 className='text-center m-0'>Historial</h3>
                    {notiHistorial.map((n, index) => {
                      return (
                        <div
                          key={index}
                          className='visiblemd'>
                          <History
                            newsData={n}
                            setSelectNoti={setSelectNoti}
                            selectNoti={selectNoti}
                          />
                        </div>
                      );
                    })}

                    <div className='visiblesm'>
                      <HistoryMovil
                        newsData={notiHistorial}
                        setSelectNoti={setSelectNoti}
                        selectNoti={selectNoti}
                      />
                    </div>
                  </div>

                  {selectNoti ? (
                    <div className='col-12 md:col-9 borderCanpaña'>
                      <Pdf
                        selectNoti={selectNoti}
                        downloadPdf={downloadPdf}
                        setDownloadPdf={setDownloadPdf}
                      />
                    </div>
                  ) : (
                    <LoaderSimple />
                  )}
                </div>
              ) : (
                <LoaderSimple />
              )}
            </div>
          </div>
        </div>

        <div className='col-12 mt-5 md:mt-8'>
          <h1 className='noticiaTitle m-0'>
            Hola Aliado, ¿Tienes una noticia por contarnos?
          </h1>
          <h1 className='subnoticiaTitle mt-0'>Escríbenos y carga tu nota</h1>
        </div>

        <div className='col-12 md:col-10 md:col-offset-1 contentForm'>
          <UploadsNoti
            selectNoti={selectNoti}
            dataUser={dataUser}
            configurations={configurations}
          />
        </div>
      </div>
    </>
  );
}

export default Campaign;
